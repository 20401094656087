<template>
  <v-container
    class="pms-settings-container"
    fluid>
    <v-row>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="auto">
            <h2>Performance Report Settings</h2>
          </v-col>
          <v-col
            cols="auto"
            class="d-flex justify-end align-end">
            <div style="width: fit-content;">
              <date-picker
                v-model="monthQuery"
                type="month" />
            </div>
            <!-- <v-btn
              class="ml-1"
              :min-width="0"
              :width="40"
              color="secondary"
              small
              @click="getBonusPools()">
              <v-icon x-small>
                mdi-magnify
              </v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="!loading"
        cols="auto"
        class="d-flex align-center">
        <span>
          <strong>Last setting date :</strong> {{ formData.updatedAt | dateTimeFormat() }}
        </span>
      </v-col>
      <v-spacer />
      <v-col
        v-if="!loading && !readonly"
        cols="12"
        md="auto">
        <v-btn
          class="mx-1"
          color="success"
          :disabled="loading"
          @click="addRow()">
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Row
        </v-btn>
        <v-btn
          class="mx-1"
          color="secondary"
          :disabled="loading || !canSave || !formData.targets.length"
          @click="onSave()">
          <v-icon small>
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-col>
      <v-col
        v-if="loading"
        cols="12">
        <v-skeleton-loader
          class="mx-auto"
          type="table" />
      </v-col>
      <v-col
        v-else
        cols="12">
        <v-simple-table class="elevation-1">
          <template #default>
            <thead>
              <tr>
                <th
                  class="text-center header">
                  % Store target
                </th>
                <th
                  v-for="(category, categoryIndex) in groupCategories"
                  :key="categoryIndex"
                  class="text-center header">
                  {{ category.name }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bonus, bonusIndex) in formData.targets"
                :key="`mainbonus-${bonusIndex}`"
                class="row-border">
                <td class="text-center column py-2">
                  <div class="w-full d-flex flex-row justify-space-between align-center">
                    <div
                      class="mx-1"
                      style="width: 50%;">
                      More than
                    </div>
                    <v-text-field
                      v-model.number="bonus.storeTarget"
                      label="Target"
                      type="number"
                      min="0"
                      outlined
                      dense
                      hide-details
                      color="secondary"
                      style="width: 50%;"
                      :disabled="loading || readonly" />
                  </div>
                </td>
                <td
                  v-for="(category, categoryIndex) in bonus.groupCategories"
                  :key="`groupCategories-${categoryIndex}-${bonusIndex}`"
                  class="column">
                  <v-text-field
                    v-model.number="category.bonus"
                    outlined
                    dense
                    type="number"
                    min="0"
                    hide-details
                    full-width
                    color="secondary"
                    class="my-2"
                    :disabled="loading || readonly" />
                </td>
                <td class="text-center column-action">
                  <v-btn
                    color="error"
                    :disabled="loading || readonly"
                    @click="deleteRow(bonusIndex)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        v-if="!loading"
        cols="12">
        <h3 class="pt-3">
          Store target setting
        </h3>
      </v-col>
      <v-col
        v-if="!loading"
        cols="12">
        <v-simple-table class="elevation-1">
          <template #default>
            <thead>
              <tr>
                <th
                  class="header">
                  Store
                </th>
                <th
                  class="text-center header">
                  Formula
                </th>
                <th
                  class="text-center header">
                  Formula Value
                </th>
                <th
                  class="text-center header">
                  Target (THB)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(warehouse, warehouseIndex) in formData.warehouseTargets"
                :key="`mainwarehouse-${warehouseIndex}`"
                class="row-border">
                <td class="column py-2">
                  {{ warehouse.name }}
                </td>
                <td
                  class="column py-2"
                  style="max-width: 200px;">
                  <v-select
                    v-model="formData.warehouseTargets[warehouseIndex].formula"
                    :items="formulaTypes"
                    item-text="label"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    color="secondary"
                    :disabled="loading || readonly" />
                </td>
                <td class="column py-2">
                  <v-text-field
                    :value="showPriceFormat(warehouse.formulaValue)"
                    outlined
                    dense
                    hide-details
                    full-width
                    color="secondary"
                    :disabled="loading || warehouse.formula === 'commission' || readonly"
                    @input="onFormulaType($event, warehouseIndex)" />
                </td>
                <td class="column py-2">
                  <v-text-field
                    :value="showPriceFormat(warehouse.target)"
                    outlined
                    dense
                    hide-details
                    full-width
                    color="secondary"
                    :disabled="loading || readonly"
                    @input="onTargetType($event, warehouseIndex)" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReviewWarehousesProvider from '@/resources/ReviewWarehouses'
import ProductGroupCategoryProvider from '@/resources/ProductGroupCategoryProvider'
import PerformanceReportSettingsProvider from '@/resources/PerformanceReportSettingsProvider'
import DatePicker from '@/components/DatePicker.vue'
import { mapActions } from 'vuex'
import { getRole } from '@/assets/js/Authentication'

const ReviewWarehousesService = new ReviewWarehousesProvider()
const ProductGroupCategoryService = new ProductGroupCategoryProvider()
const PerformanceReportSettingsService = new PerformanceReportSettingsProvider()

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      loading: false,
      warehouses: [],
      groupCategories: [],
      formulaTypes: [
        {
          value: 'commission',
          label: 'Commission'
        },
        {
          value: 'fixed',
          label: 'Fixed'
        }
      ],
      bonusPools: null,
      formData: {
        targets: [],
        warehouseTargets: []
      },
      total: 0,
      pageCount: 0,
      monthQuery: this.$dayjs().format('YYYY-MM'),
      query: {
        page: 1,
        limit: 9999,
        sortBy: ['priority'],
        sortDesc: [false]
      }
    }
  },
  computed: {
    canSave () {
      return JSON.stringify(this.bonusPools) !== JSON.stringify(this.formData)
    },
    readonly () {
      const availableRoles = ['management', 'developer']

      return !availableRoles.includes(getRole())
    }
  },
  watch: {
    monthQuery () {
      this.getBonusPools()
    }
  },
  async mounted () {
    await this.getWarehouses()
    await this.getGroupCategories()
    this.getBonusPools()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getWarehouses () {
      try {
        this.loading = true

        const { data } = await ReviewWarehousesService.getReviewWarehouses()

        this.warehouses = data
          .map((w) => w.warehouse)
          .filter((w) => w?.id)
          .sort((a, b) => a.name.localeCompare(b.name))
      } catch (error) {
        console.error('getWarehouses', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING WAREHOUSES]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getGroupCategories () {
      try {
        this.loading = true

        const sortBy = this.query.sortBy[0]
        const sortOrder = this.query.sortDesc[0] ? 'desc' : 'asc'
        const { data } = await ProductGroupCategoryService.getAll({
          ...this.query,
          sortBy,
          sortOrder,
          search: this.search
        })

        this.total = data.total
        this.pageCount = data.pages
        this.groupCategories = data.results
      } catch (error) {
        console.error('getGroupCategories', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING GROUP CATEGORIES]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getBonusPools () {
      try {
        this.loading = true

        const [year, month] = this.monthQuery.split('-')
        const { data } = await PerformanceReportSettingsService.getPerformanceReportSettings({
          month,
          year
        })

        this.bonusPools = data

        if (this.bonusPools) {
          const groupCategoriesIds = this.groupCategories.map((g) => g.id)
          const tmpFormData = JSON.parse(JSON.stringify(this.bonusPools))

          tmpFormData.targets = tmpFormData.targets.map((v) => ({
            ...v,
            groupCategories: v.groupCategories.filter((g) => groupCategoriesIds.includes(g.id))
          }))

          this.formData = tmpFormData
        }

        if (!this.bonusPools?.targets?.length) {
          this.formData.targets = [
            {
              storeTarget: 0,
              groupCategories: this.groupCategories.map((cat) => ({
                id: cat.id,
                name: cat.name,
                bonus: 0
              }))
            }
          ]
        }

        this.formData.warehouseTargets = this.warehouses.map((w) => {
          const warehouseTarget = this.formData.warehouseTargets.find((t) => t.id === w.id)

          return {
            id: warehouseTarget?.id || w.id,
            name: warehouseTarget?.name || w.name,
            target: warehouseTarget?.target || 0,
            formula: warehouseTarget?.formula || 'commission',
            formulaValue: warehouseTarget?.formulaValue || 0
          }
        })
      } catch (error) {
        console.error('getBonusPools', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING BONUS POOLS]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSave () {
      try {
        this.loading = true

        const [year, month] = this.monthQuery.split('-')
        await PerformanceReportSettingsService.createPerformanceReportSettings({
          ...this.formData,
          month: Number(month),
          year: Number(year)
        })

        this.setSnackbar({
          value: true,
          message: 'Saved settings',
          type: 'success'
        })
        this.getBonusPools()
      } catch (error) {
        console.error('onSave', error)
        this.setSnackbar({
          value: true,
          message: `[SAVING]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    addRow () {
      this.formData.targets.push({
        storeTarget: 0,
        groupCategories: this.groupCategories.map((cat) => ({
          id: cat.id,
          name: cat.name,
          bonus: 0
        }))
      })
    },
    deleteRow (index) {
      this.formData.targets.splice(index, 1)
    },
    showPriceFormat (text) {
      const num = parseFloat(text)

      if (num === 0 || num) {
        return parseFloat(text).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '0'
    },
    onTargetType (text, warehouseIndex) {
      const tmpText = text.replaceAll(',', '')

      if (tmpText !== '' && !Number.isNaN(tmpText)) {
        this.formData.warehouseTargets[warehouseIndex].target = Number(tmpText)
      } else {
        this.formData.warehouseTargets[warehouseIndex].target = 0
      }
    },
    onFormulaType (text, warehouseIndex) {
      const tmpText = text.replaceAll(',', '')
      const isNumber = new RegExp('^[0-9]+$').test(tmpText)

      if (tmpText !== '' && isNumber) {
        this.formData.warehouseTargets[warehouseIndex].formulaValue = Number(tmpText)
      } else {
        this.formData.warehouseTargets[warehouseIndex].formulaValue = 0
      }
    }
  }
}
</script>

<style scoped>
.pms-settings-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.settings-table {
  overflow-x: auto;
}
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content;
}
.title {
  font-weight: 600;
  padding-right: 5px;
}
.text-red {
  color: red !important;
}
.header {
  font-size: 1rem !important;
}
.row-border {
  border-bottom: 1px solid grey;
}
.column {
  min-width: 200px;
  padding: 0 8px !important;
}
.column-action {
  min-width: 100px;
  padding: 0 8px !important;
}
</style>
